import React, { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import SubscriptionForm from '../components/SubscriptionForm';
import { MembershipPlan } from '../store/membership/membership.types';
import CloseDialog from '../components/CloseDialog';
import { publish } from '../events';

interface BookingCtxValues {
  openSubscriptions: (plan: MembershipPlan | null, isRenew?: boolean) => void;
  closeSubscriptions: () => void;
  selectedPlan: MembershipPlan | null;
  renewMembership: boolean;
}

export const BookingContext = React.createContext<BookingCtxValues>({
  openSubscriptions: () => { },
  closeSubscriptions: () => { },
  selectedPlan: null,
  renewMembership: false,
});

const BookingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpenSubscriptions, setIsOpenSubscriptions] = useState<boolean>(
    false,
  );
  const [selectedPlan, setSelectedPlan] = useState<MembershipPlan | null>(null);
  const [renewMembership, setRenewMembership] = useState<boolean>(false);

  const values = useMemo(
    () => ({
      openSubscriptions: (
        selectedPlan: MembershipPlan | null,
        isRenew?: boolean,
      ) => {
        if (selectedPlan) {
          setSelectedPlan(selectedPlan);
          publish('InitiateCheckout', {
            content_category: 'Membership Plan',
            content_ids: [selectedPlan.id],
            contents: [{
              id: selectedPlan.id,
              quantity: 1,
              name: selectedPlan.name,
            }],
            num_items: 1,
            currency: selectedPlan.currency,
            value: selectedPlan.price,
          });
        }
        if (isRenew) setRenewMembership(true);
        setIsOpenSubscriptions(true);
      },
      closeSubscriptions: () => {
        setIsOpenSubscriptions(false);
        setRenewMembership(false);
        setSelectedPlan(null);
      },
    }),
    [],
  );

  return (
    <BookingContext.Provider
      value={{
        ...values,
        selectedPlan,
        renewMembership,
      }}
    >
      {children}
      <Dialog
        onClose={values.closeSubscriptions}
        open={isOpenSubscriptions}
        className="ru-dialog"
      >
        <SubscriptionForm />
        <CloseDialog onClick={values.closeSubscriptions} />
      </Dialog>
    </BookingContext.Provider>
  );
};

export default BookingProvider;
