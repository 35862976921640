import { Lounge } from '../lounge/lounge.types';

export enum ETImeSlotProductType {
  event = 'event',
  groupRide = 'group_ride',
  timeSlot = 'time_slot',
}

export enum EBookingStatus {
  pending = 'pending',
  draft = 'draft',
}

export enum EAvailableDateStatus {
  Bookable = 'bookable',
  FullyBooked = 'fully_booked',
  NonBookable = 'non_bookable',
}

export interface TimeSlot {
  available: number;
  currency: string;
  description: string;
  duration_in_minutes: number;
  id: string;
  name: string;
  price: string | number;
  duration: string;
  vat: string;
  product_type: ETImeSlotProductType;
  product_id: string;
  race_formats: string;
}

export interface AvailableDate {
  date: string;
  status: string;
  color: string;
  usage_color: string;
  usage_percentage: number;
}

export interface SummaryDiscount {
  name: string;
  value: number;
  type: string;
  label: string;
  price_effect: string;
}

export interface SummaryProduct {
  base_price: number;
  final_price: number;
  quantity: number;
  vat: string;
  product_name?: string;
  discounts: SummaryDiscount[];
}

export interface SummaryType {
  currency: string;
  base_price_sum: number;
  final_price_sum: number;
  products: SummaryProduct[];
}

export interface EventProduct {
  id: string; // Product ID
  quantity: number;
}

export interface EventInformation {
  CateringProductID?: string | null;
  CateringSpecialRequest?: string | null;
  FormatProductID?: string | null;
  TrophiesProductID?: string | null;
  TrophiesFirstLine?: string | null;
  TrophiesSecondLine?: string | null;
  CarBrandingProductID?: string | null;
  TrackBrandingProductID?: string | null;
  EventTypeID?: string | null;
  AdditionalRequest?: string | null;
  ParticipantsCount?: number | null;
  InternalReservedFor?: string | null;
  Organiser?: {
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    Subscribe: boolean;
  };
  BillingData?: {
    CompanyName: string;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    Region: string;
    CountryID: string;
    ZipCode: string;
    TaxNumber: string;
    IsCompany: boolean;
  };
}

export interface BookingPayload {
  existing_reservation_id: string;
  start_time: string | null;
  time_slot_id: string | null;
  participants_count: number;
  simulators: number | null;
  lounge_id: string;
  rides_sharing: boolean;
  internal: boolean;
  status: EBookingStatus;
  coupon_code: string | null;
  car_branding_logo?: File | string | null;
  track_branding_logo?: File | string | null;
  event_information: EventInformation | null;
  card_token?: string;
  paid_in_cash?: boolean;
  save_card?: boolean;
  customer_id?: string;
}

export interface Booking {
  id: string;
  date: string;
  start_time: string | null;
  simulators_count: number;
  duration_in_hours: number;
  lounge_id: string;
  time_slot_id: string | null;
  internal: boolean;
  rides_sharing: boolean;
  status: EBookingStatus;
  car_branding_logo?: string | null;
  track_branding_logo?: string | null;
  time_slot: TimeSlot;
  lounge: Lounge;
  event_information: EventInformation | null;
  coupon_code: string;
}

export interface Ride {
  id: string;
  start_time: string;
  time_slot: {
    name: string;
  };
  lounge: {
    name: string;
  };
}
