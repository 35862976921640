import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import styles from './BookingWidget.module.scss';
import classNames from 'classnames';

const BookingWidget = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleBookButtonClick = useCallback(() => {
    navigate('/public/ride-booking');
  }, [navigate]);

  return (
    <div className={classNames('dashboard-widget', styles.root)}>
      <div>
        <h3>{t('booking.title')}</h3>
        <p className="dashboard-widget-text">
          {t('booking.widgetText')}
        </p>
      </div>
      <Button
        fullWidth
        className={styles.button}
        label={t('booking.pageBtnLabel')}
        onClick={handleBookButtonClick}
      />
    </div>
  );
};

export default BookingWidget;
