import React from 'react';
import CardForm from '../../components/CardForm';

const Billing = () => {
  return (
    <div className="Billing">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <CardForm />
        </div>
      </div>
    </div>
  );
};

export default Billing;
