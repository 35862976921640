import { Dispatch } from 'react';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { Booking, Ride } from '../booking/booking.types';
import { DashboardActionTypes, GET_DRAFT_EVENTS } from './dashboard.types';
import { Customer } from '../customer/customer.types';

export const getUpcomingRides = async () => {
  try {
    const rides = await api.get<{ data?: Ride[] }>('/dashboard/upcoming_rides');
    return rides?.data?.data ?? [];
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string') toastUtil('error', error.data.meta.message);
    return [];
  }
};

export const getDraftEvents = async (
  dispatch: Dispatch<DashboardActionTypes>,
): Promise<Booking[]> => {
  let draftEvents: Booking[] = [];

  try {
    const events = await api.get<TRumpApiResponseData<Booking[]>>('/dashboard/draft_events');
    draftEvents = events.data.data;
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string')
      toastUtil('error', error.data.meta.message);
  }

  dispatch({
    type: GET_DRAFT_EVENTS,
    payload: draftEvents,
  });

  return draftEvents;
};

export const getDraftById = async (
  dispatch: Dispatch<DashboardActionTypes>,
  id: string,
): Promise<Booking | null> => {
  return (
    (await getDraftEvents(dispatch)).find((draft) => draft.id === id) || null
  );
};

export const getCustomerInfo = async () => {
  try {
    const info = await api.get<{ data?: Customer }>('/dashboard/customer_info');
    return info.data.data;
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string') toastUtil('error', error.data.meta.message);
  }
};
