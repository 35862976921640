export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const TOGLE_NOTIFICATIONS = 'TOGLE_NOTIFICATIONS';

export interface Notification {
  id: string;
  title: string;
  content: string;
  seen: boolean;
  type: string;
  created_at: string;
}

export interface NotificationsState {
  notifications: Notification[];
  unseen_count: number;
}

export interface SingleNotificationState {
  notification: Notification | null;
  unseen_count: number;
}

interface GetNotificationsAction {
  type: typeof GET_NOTIFICATIONS;
  payload: NotificationsState;
}

interface DeleteNotificationsAction {
  type: typeof DELETE_NOTIFICATIONS;
  payload: SingleNotificationState;
}

interface ToggleNotificationsAction {
  type: typeof TOGLE_NOTIFICATIONS;
  payload: string;
}

export type NotificationActionTypes =
  | GetNotificationsAction
  | DeleteNotificationsAction
  | ToggleNotificationsAction;
