import React, { useEffect } from 'react';
import NotificationsTable from './NotificationsTable';
import { useTranslation } from 'react-i18next';
import { getNotifications } from '../../store/notification/notification.actions';
import useWithDispatch from '../../hooks/useWithDispatch';

const Notifications = () => {
  const [t] = useTranslation();
  const notificationsWithDispatch = useWithDispatch(getNotifications);

  useEffect(() => {
    notificationsWithDispatch();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Notifications">
      <div className="row justify-content-center">
        {/* <div className="col-md-4">Notification Settings</div> */}
        <div className="col-md-6">
          <h2>{t('notifications.notifications')}</h2>
          <NotificationsTable />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
