import {
  NotificationActionTypes,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  TOGLE_NOTIFICATIONS,
  Notification,
} from './notification.types';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { AxiosError } from 'axios';

export const getNotifications = async (
  dispatch: Dispatch<NotificationActionTypes>,
) => {
  try {
    const res = await api.get<{ data?: { notifications: Notification[]; unseen_count: number } }>(`${API_ROOT}/notifications`);
    if (typeof res === 'undefined' || !res) return;
    const notificationsData = res?.data?.data ?? { notifications: [], unseen_count: 0 };

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: notificationsData,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const deleteNotificitaion = async (
  dispatch: Dispatch<NotificationActionTypes>,
  id: string,
) => {
  try {
    const res = await api.delete<{ data?: { notification: Notification; unseen_count: number } }>(`${API_ROOT}/notifications/${id}`);
    if (typeof res === 'undefined' || !res) return;
    const notificationsData = res?.data?.data ?? { notification: null, unseen_count: 0 };

    dispatch({
      type: DELETE_NOTIFICATIONS,
      payload: notificationsData,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const toggleSeen = async (
  dispatch: Dispatch<NotificationActionTypes>,
  id: string,
) => {
  try {
    await api.patch(`/notifications/${id}`);
    dispatch({
      type: TOGLE_NOTIFICATIONS,
      payload: id,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    if (err.response && 'meta' in err.response.data && typeof err.response.data.meta.message === 'string')
      toastUtil('error', err.response.data.meta.message);
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};
