import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWitSelection from '../../../hooks/useWithSelection';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { championshipsSelector } from '../../../store/championships/championships.selector';
import {
  getMonths,
  getRankings,
  getLapTime,
} from '../../../store/track/track.actions';
import { toastUtil } from '../../../utils/toast.utils';
import { Championship } from '../../../store/championships/championships.types';
import { Month } from '../../../store/track/track.types';

import './RankingWidget.scss';
import RankingItem from '../../Ranking/RankingItem';
import { AxiosError } from 'axios';
import classNames from 'classnames';

const RankingWidget: React.FC<{ className?: string }> = ({ className }) => {
  const [t] = useTranslation();
  const championships = useWitSelection(championshipsSelector);
  const fetchMonths = useWithDispatch(getMonths);
  const [ranking, setRanking] = useState<{ ladderboard: RankingItem[] }>();

  useEffect(() => {
    (async () => {
      try {
        const activeChampionship = championships.find(
          (championship: Championship) => championship.active,
        );

        if (activeChampionship) {
          const months = await fetchMonths(activeChampionship.track_id);
          const activeMonth = months.find((month: Month) => month.active);
          if (!activeMonth) return;
          const championshipRanking = await getRankings(
            activeChampionship.track_id,
            activeMonth.value,
            activeChampionship.car_id,
          );
          setRanking(championshipRanking);
        }
      }
      catch (error) {
        const err = error as AxiosError<TRumpApiErrorResponseData>;
        if (err?.response && 'meta' in err.response && typeof err.response.data.meta.message === 'string')
          toastUtil('error', err.response.data.meta.message);
      }
    })();
    // eslint-disable-next-line
  }, [championships]);

  if (!ranking?.ladderboard.length) return '';

  return (
    <div className={classNames('dashboard-widget', 'fixed-header', 'RankingWidget', className)}>
      <div className="dashboard-widget-header">
        <h3>{t('ranking.ranking')}</h3>
        <Link to="/ranking">{t('dashboard.viewAll')}</Link>
      </div>
      <div className="dashboard-widget-body">
        <div className="RankingWidget-row row-header">
          <div>#</div>
          <div>{t('ranking.racer')}</div>
          <div>{t('ranking.lapTime')}</div>
        </div>
        {ranking
        && ranking.ladderboard.length > 0
        && ranking.ladderboard.map((racer, index) => (
          <div
            className={`RankingWidget-row ${
              racer.current_customer ? 'is-customer' : ''
            } ${index === 0 ? 'is-first' : ''}`}
            key={racer.customer_id + index}
          >
            <div>
              {index + 1}
              .
            </div>
            <div>{racer.customer_fullname}</div>
            <div>{getLapTime(racer.best_lap)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RankingWidget;
