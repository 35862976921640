import { FormModelField } from '../../../types/form.types';

interface Values {
  nickname: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  default_lounge_id: string;
  address1: string;
  address2: string;
  country_id: string;
  gender_id: string;
  city: string;
  dob: string;
}

export const initialValues: Values = {
  nickname: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  default_lounge_id: '',
  address1: '',
  address2: '',
  country_id: '',
  gender_id: '',
  city: '',
  dob: '',
};

export const formModel: FormModelField[] = [
  {
    name: 'nickname',
    i18nKey: 'field.nickname',
    type: 'text',
    required: true,
  },
  {
    name: 'first_name',
    i18nKey: 'field.first_name',
    type: 'text',
    required: true,
  },
  {
    name: 'last_name',
    i18nKey: 'field.last_name',
    type: 'text',
    required: true,
  },
  {
    name: 'email',
    i18nKey: 'field.email',
    type: 'text',
    required: true,
  },
  {
    name: 'phone_number',
    i18nKey: 'field.phone_number',
    type: 'text',
  },
  {
    name: 'default_lounge_id',
    i18nKey: 'field.default_lounge_id',
    type: 'select',
    list: [],
    required: true,
  },
  {
    name: 'company',
    i18nKey: 'field.company',
    type: 'text',
  },
  {
    name: 'body_weight',
    i18nKey: 'field.body_weight',
    type: 'number',
  },
  {
    name: 'body_height',
    i18nKey: 'field.body_height',
    type: 'number',
  },
  {
    name: 'address1',
    i18nKey: 'field.address1',
    type: 'text',
  },
  {
    name: 'address2',
    i18nKey: 'field.address2',
    type: 'text',
  },
  {
    name: 'country_id',
    i18nKey: 'field.country_id',
    type: 'select',
    list: [],
  },
  {
    name: 'city',
    i18nKey: 'field.city',
    type: 'text',
  },
  {
    name: 'zip_code',
    i18nKey: 'field.zip_code',
    type: 'text',
  },
  {
    name: 'gender_id',
    i18nKey: 'field.gender_id',
    type: 'select',
    list: [],
  },
  {
    name: 'dob',
    i18nKey: 'field.dob',
    type: 'datepicker',
  },
  {
    name: 'pob_country_id',
    i18nKey: 'field.pob_country_id',
    type: 'select',
    list: [],
  },
  {
    name: 'fav_lounge_id',
    i18nKey: 'field.fav_lounge_id',
    type: 'select',
    list: [],
  },
  {
    name: 'fav_simulator_id',
    i18nKey: 'field.fav_simulator_id',
    type: 'select',
    list: [],
  },
];
