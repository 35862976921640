import { MembershipPlan } from '../membership/membership.types';
export const CHANGE_LANG = 'CHANGE_LANG';
export const SET_MEMBER = 'SET_MEMBER';
export const SET_QUOTAS = 'SET_QUOTAS';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
export const DELETE_PAYMENT_INFO = 'DELETE_PAYMENT_INFO';

export interface BaseCustomer {
  id: string;
  email: string;
  nickname: string;
  first_name: string;
  last_name: string;
  password: string;
  profile_data_incomplete: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  zip_code?: string;
  gender_id?: string;
  country_id?: string;
  pob_country_id?: string;
  company?: string;
  body_weight?: string;
  body_height?: string;
  dob?: string;
  fav_lounge_id?: string;
  fav_simulator_id?: string;
  phone_number?: string;
  registered_at?: string;
  preferred_language?: string;
  active: boolean;
  roles: string[];
  activated_at?: string;
  full_name?: string;
  unconfirmed_email: null | string;
  shareable_package_count: null | number;
  default_lounge_id?: string;
  profile_pic_url?: string;
  social_networks?: SocialNetwork[];
}

export interface Customer extends BaseCustomer {
  membership_plan?: MembershipPlan;
  experiences: number;
  tracks: number;
}

export interface PaymentInfo {
  card_brand: string;
  card_number_fragment: string;
  card_expired: boolean;
  card_expires_on: string;
}

export interface Quota {
  pricing: {
    label: string;
    description: string;
  };
  limit: {
    customer_limit: {
      show: boolean;
      total: string;
      used: string;
      description: string;
    };
  };
}

export interface AccountActivation {
  customer_id: string;
  token: string;
}

export interface CustomerActivation {
  customer_id: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface SocialNetwork {
  id: string;
  social_network: string;
  url: string | null;
  username: string | null;
}

export interface CustomerState {
  customer: Customer | null;
  paymentInfo: PaymentInfo | null;
  quotas: Quota[] | null;
}

export interface SignOutAction {
  type: typeof SIGN_OUT;
}

export interface SetCustomerAction {
  type: typeof SET_MEMBER;
  payload: Customer;
}

export interface SetQuotasAction {
  type: typeof SET_QUOTAS;
  payload: Quota[];
}

interface UpdatePaymentInfoAction {
  type: typeof UPDATE_PAYMENT_INFO;
  payload: PaymentInfo;
}

interface ChangeLangAction {
  type: typeof CHANGE_LANG;
}

export type CustomerActionTypes =
  | SignOutAction
  | SetCustomerAction
  | UpdatePaymentInfoAction
  | ChangeLangAction
  | SetQuotasAction;
