import React, { useMemo } from 'react';
import mergeClassNames from 'classnames';
import loaderAnimation from './ru-loader.json';
import loaderAnimationNeon from './ru-loader-neon-blue.json';
import loaderAnimationWhite from './ru-loader-white.json';
import loaderAnimationMediumBlue from './ru-loader-medium-blue.json';
import loaderAnimationDarkBlue from './ru-loader-dark-blue.json';
import styles from './Loader.module.scss';
import LottieSvg, { ILottieSvgOptions } from '../LottieSvg/LottieSvg';

const animations = {
  black: loaderAnimation,
  white: loaderAnimationWhite,
  neon: loaderAnimationNeon,
  'dark-blue': loaderAnimationDarkBlue,
  'medium-blue': loaderAnimationMediumBlue,
};

export interface ILoaderClasses {
  root?: string;
}

export interface ILoaderProps {
  classes?: ILoaderClasses;
  height?: number | string;
  width?: number | string;
  color?: 'black' | 'white' | 'neon' | 'dark-blue' | 'medium-blue';
  fullSize?: boolean;
}

const Loader = ({
  classes,
  color = 'black',
  height = 200,
  width = 200,
  fullSize = false,
}: ILoaderProps) => {
  const options = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animations[color],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    } satisfies ILottieSvgOptions),
    [color],
  );

  const { root: rootClassName } = useMemo(() => classes || {}, [classes]);

  const mergedRootClassName = useMemo(
    () =>
      mergeClassNames({
        [styles.loader]: true,
        [rootClassName || '']: true,
      }),
    [rootClassName],
  );

  const loader = <LottieSvg width={width} height={height} options={options} />;

  if (fullSize) return <div className={mergedRootClassName}>{loader}</div>;

  return loader;
};

export default Loader;
