import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import './Delete.scss';
import { toastUtil } from '../../../utils/toast.utils';

interface Props {
  onSuccess: () => void;
}

const Delete = ({ onSuccess }: Props) => {
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleClick = () => {
    try {
      setSubmitting(true);
      onSuccess();
      setSubmitting(false);
    }
    catch (err) {
      const error = err as TRumpApiRequestError;
      if ('message' in error && error.message === 'canceled') return;

      const msg = 'meta' in error ? error.meta.message : error.message;
      if (typeof msg === 'string') toastUtil('error', msg);
      setSubmitting(false);
    }
  };

  return (
    <div className="Delete">
      <h2>{t('profile.deleteTitle')}</h2>
      <p>{t('profile.deleteSubtitle')}</p>
      <Button
        onClick={handleClick}
        label={t('profile.yesConfirm')}
        disabled={submitting}
        isLoading={submitting}
      />
    </div>
  );
};

export default Delete;
