import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getEvent } from '../../../store/events/events.actions';
import { Event } from '../../../store/events/events.types';
import { format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';

import './EventDetails.scss';
import { toastUtil } from '../../../utils/toast.utils';

const langs = {
  de,
  en,
  es,
};

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState<Event | undefined>();
  const [t, i18n] = useTranslation();

  const fetchEvents = async (lang: string) => {
    try {
      const res = await getEvent(id, lang);
      setEvent(res);
    }
    catch (err) {
      const error = err as TRumpApiRequestError;
      if ('message' in error && error.message === 'canceled') return;

      const msg = 'meta' in error ? error.meta.message : error.message;
      if (typeof msg === 'string') toastUtil('error', msg);
    }
  };

  useEffect(() => {
    fetchEvents(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <div className="EventDetails">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {event && (
            <>
              <Link to="/events" className="EventDetails-back">
                {t('events.back')}
              </Link>
              <div className="EventDetails-body">
                <span className="EventDetails-date">
                  {format(new Date(event.start_time), 'MMM dd.yyyy', {
                    locale: langs[i18n.language as keyof typeof langs],
                  })}
                  {' '}
                  -
                  {' '}
                  {format(new Date(event.end_time), 'MMM dd.yyyy @HH:mm', {
                    locale: langs[i18n.language as keyof typeof langs],
                  })}
                </span>
                <h3>{event.title}</h3>
                <img src={event.featured_image_large} alt="" />
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {event.description}
                </ReactMarkdown>
                {event.link_url && (
                  <a
                    href={event.link_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.link_title}
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
