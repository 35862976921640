import React from 'react';
import Button from '../Button';
import logo from '../../images/ru-logo-blue-medium.svg';
import './DialogSuccess.scss';

interface DialogSuccess {
  title: string;
  body?: string;
  label: string;
  onClick: () => void;
}

const DialogSuccess = ({ title, body, onClick, label }: DialogSuccess) => {
  return (
    <div className="DialogSuccess">
      <img src={logo} alt="Racing Unleashed" />
      <h3>{title}</h3>
      {body && <p>{body}</p>}
      <Button label={label} onClick={onClick} />
    </div>
  );
};

export default DialogSuccess;
