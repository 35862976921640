import React, { useState, useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import useWithDispatch from '../../../hooks/useWithDispatch';
import {
  deleteNotificitaion,
  toggleSeen,
} from '../../../store/notification/notification.actions';
import { Notification as INotification } from '../../../store/notification/notification.types';

const Notification = ({
  created_at,
  type,
  id,
  title,
  content,
  seen,
}: INotification) => {
  const location = useLocation() as Location<{ selectedNotification?: string }>;
  const isActive = location.state && location.state.selectedNotification === id;
  const element = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const deleteNotification = useWithDispatch(deleteNotificitaion);
  const toggleSeenWithDispatch = useWithDispatch(toggleSeen);

  const toggleBody = () => {
    setVisible(!visible);
    if (seen) return;
    toggleSeenWithDispatch(id);
  };

  useEffect(() => {
    if (isActive) {
      toggleBody();
      element.current?.scrollIntoView({ block: 'center' });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Notifications-cells" key={id} ref={element}>
      <span>{format(new Date(created_at), 'dd.MM.yyyy')}</span>
      <span onClick={toggleBody} className="Notification-content">
        <span className={`Notification-type ${type}`}>·</span>
        {title}
        {visible && <p>{content}</p>}
      </span>
      <div className="Notification-delete">
        <span onClick={() => deleteNotification(id)} />
      </div>
    </div>
  );
};

export default Notification;
