import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import styles from './ExperienceStep.module.scss';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';
import { dateLocales } from '../../../../../constants/dateLocales';
import StyledTooltip from '../../../../../components/StyledTooltip/StyledTooltip';
import { ReactComponent as InfoIcon } from '../../../../../images/Info-current-color.svg?tsx';
import { ReactComponent as ArrowIcon } from '../../../../../images/arrow-current-color.svg?tsx';
import Button from '../../../../../components/Button';
import { Lounge } from '../../../../../store/lounge/lounge.types';
import LinearCalendar, {
  ILinerCalendarOnGenerateChunkProps,
} from '../../../../../components/Calendar/LinearCalendar';
import BookingDetailsDrawer from '../../../../../components/BookingDetails/BookingDetailsDrawer';
import { BookingPriceDetail } from '../../../../../components/BookingPriceDetails/BookingPriceDetails';
import { BookingDetail } from '../../../../../components/BookingDetailsList/BookingDetailsList';
import { ICalendarDayProps } from '../../../../../components/Calendar/CalendarDay';
import { timeFromIsoDate } from '../../../../../utils/time-from-iso-date.utils';
import { RIDE_BESTSELLER_DURATION } from '../../../constants/ride-bestseller-duration';
import Loader from '../../../../../components/Loader';
import {
  SummaryType,
  TimeSlot,
} from '../../../../../store/booking/booking.types';
import { round } from '../../../../../utils/round.utils';
import chunk from 'lodash/chunk';
import { useMediaQuery } from '@mui/material';
import { IRideBookingForm } from '../../../RideBooking';
import { Controller } from '../../../../../components/FormField/Controller';
import { CounterInput } from '@components/inputs';

type TExperienceStepProps = {
  selectedLounge: Lounge | null;
  onForward: () => void;
  onBackward: () => void;
  closestAvailableDate?: Date;
  datesData?: Record<
    string,
    Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>
  >;
  onDatesRangeDataRequest?: (props: ILinerCalendarOnGenerateChunkProps) => void;
  onParticipantsAmountChange?: () => void;
  priceSummary: SummaryType | null;
  priceDetails?: BookingPriceDetail[];
  summaryDetails?: BookingDetail[];
  availableTimes: string[];
  timeSlots: TimeSlot[];
  currency?: string;
  vat?: string;

  isTimeSlotsReady?: boolean;
  isClosestAvailableDateReady?: boolean;
  isAvailableTimesReady?: boolean;
  isPriceReady?: boolean;
};

const MIN_PARTICIPANTS = 1;

const ExperienceStep: React.FC<TExperienceStepProps> = ({
  selectedLounge,
  onBackward,
  onForward,
  closestAvailableDate,
  datesData,
  onDatesRangeDataRequest,
  priceDetails,
  summaryDetails,
  availableTimes,
  timeSlots,
  priceSummary,
  currency,
  vat,

  isTimeSlotsReady,
  isClosestAvailableDateReady,
  isAvailableTimesReady,
  isPriceReady,
}) => {
  const [t, i18n] = useTranslation();
  const { control, setValue, watch } = useFormContext<IRideBookingForm>();

  const { timeSlotId, time, date } = watch(['timeSlotId', 'time', 'date']);

  const maxParticipants = selectedLounge
    ? selectedLounge.available_simulators
    : MIN_PARTICIPANTS;

  const handleSelectDate = useCallback(
    (selectedDate: Date) => {
      setValue('date', selectedDate);
    },
    [setValue],
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const defaultGeneratedCalendarDayProps = useMemo(
    () => ({
      loading: true,
    }),
    [],
  );

  const simulatorsText = t('booking.simulators').toLowerCase();

  const timeList = useMemo(
    () =>
      availableTimes.map((timeSlot) => (
        <div
          key={timeSlot}
          aria-checked={timeSlot === time}
          className={classNames(styles.card, styles.timeSlots__slot)}
          onClick={() => setValue('time', timeSlot)}
        >
          {timeFromIsoDate(timeSlot)}
        </div>
      )),
    [availableTimes, time, setValue],
  );

  const isForwardButtonDisabled = useMemo(
    () => !isAvailableTimesReady,
    [isAvailableTimesReady],
  );

  const isTabletScreenSize = useMediaQuery(
    'only screen and (min-width: 768px) and (max-width: 1170px)',
  );

  const isDesktopScreenSize = useMediaQuery(
    'only screen and (min-width: 1170px)',
  );

  const timeSlotsChunkSize = useMemo(() => {
    if (isDesktopScreenSize) return 4;
    if (isTabletScreenSize) return 2;
    return 1;
  }, [isTabletScreenSize, isDesktopScreenSize]);

  const durationListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!durationListRef.current) return;
    durationListRef.current.style.setProperty(
      '--ExperienceStep__duration__list--firstRowItems',
      Math.min(timeSlots.length, timeSlotsChunkSize).toString(),
    );
  }, [timeSlots, timeSlotsChunkSize]);

  return (
    <div className={styles.experienceStep}>
      <div className={styles.participants}>
        <h4 className={styles.experienceStep__title}>
          {t('booking.steps.experience.participants.title')}
        </h4>
        <div className={styles.participants__info}>
          {t('booking.steps.experience.participants.discountInfo')}

          <StyledTooltip
            arrow
            enterTouchDelay={0}
            placement="top"
            title={(
              <div className={styles.discountTooltip}>
                <div className={styles.discountTooltip__title}>
                  {t('booking.steps.experience.discountTooltip.title')}
                </div>
                <div className={styles.discountTooltip__row}>
                  {'1-3 ' + simulatorsText}
                  {' '}
                  <ArrowIcon width={16} />
                  {' '}
                  -0%
                </div>
                <div className={styles.discountTooltip__row}>
                  {'4-5 ' + simulatorsText}
                  {' '}
                  <ArrowIcon width={16} />
                  {' '}
                  -10%
                </div>
                <div className={styles.discountTooltip__row}>
                  {'6-8 ' + simulatorsText}
                  {' '}
                  <ArrowIcon width={16} />
                  {' '}
                  -15%
                </div>
                <div className={styles.discountTooltip__row}>
                  {'9-10 ' + simulatorsText}
                  {' '}
                  <ArrowIcon width={16} />
                  {' '}
                  -20%
                </div>
              </div>
            )}
          >
            <InfoIcon />
          </StyledTooltip>
        </div>
        <Controller
          control={control}
          name="participantsAmount"
          render={({ value, onChange }) => (
            <CounterInput
              integer
              min={MIN_PARTICIPANTS}
              max={maxParticipants}
              increaseButtonTooltipTitle={t('booking.steps.experience.maxTooltip', {
                value: maxParticipants,
              })}
              decreaseButtonTooltipTitle={t('booking.steps.experience.minTooltip', {
                value: MIN_PARTICIPANTS,
              })}
              className={styles.participants__counter}
              value={value}
              onChange={(newValue) => {
                if (!newValue) return;
                onChange(newValue);
              }}
            />
          )}
        />
      </div>
      <div className={styles.duration}>
        <h4 className={styles.experienceStep__title}>
          {t('booking.steps.experience.duration.title')}
        </h4>
        <div className={styles.duration__info}>
          {t('booking.steps.experience.duration.info')}
        </div>
        <div className={styles.duration__list} ref={durationListRef}>
          {!isTimeSlotsReady ? <Loader fullSize width={80} height={80} /> : null}
          {chunk(timeSlots, timeSlotsChunkSize).map((timeSlotsChunk) => (
            <div
              className={classNames(
                styles.duration__row,
                timeSlotsChunk.some(
                  (slot) =>
                    slot.duration_in_minutes === RIDE_BESTSELLER_DURATION,
                )
                  ? styles.duration__bestsellerRow
                  : undefined,
              )}
              key={timeSlotsChunk.map((slot) => slot.id).join('-')}
            >
              {timeSlotsChunk.map(
                ({
                  id,
                  price,
                  duration_in_minutes,
                  currency,
                  race_formats,
                }) => (
                  <div
                    aria-checked={timeSlotId === id}
                    key={id}
                    className={classNames(
                      styles.card,
                      styles.durationCard,
                      RIDE_BESTSELLER_DURATION === duration_in_minutes
                        ? styles.durationCard_bestseller
                        : undefined,
                    )}
                    onClick={() => {
                      setValue('timeSlotId', id);
                    }}
                  >
                    {RIDE_BESTSELLER_DURATION === duration_in_minutes
                      ? (
                          <div className={styles.durationCard__bestseller}>
                            {t('booking.steps.duration.bestseller')}
                          </div>
                        )
                      : (
                          ''
                        )}
                    <div className={styles.durationCard__content}>
                      <h4 className={styles.experienceStep__title}>
                        <input
                          className={styles.durationCard__radio}
                          type="radio"
                          checked={timeSlotId === id}
                          onChange={() => {}}
                        />
                        {duration_in_minutes + ' min'}
                      </h4>
                      <div className={styles.durationCard__listLabel}>
                        {t('booking.steps.experience.duration.cardOptionsInfo') + ':'}
                      </div>
                      {race_formats
                        ? (
                            <ul>
                              {race_formats.split(', ').map((detail, index) => (
                                <li key={index}>{detail}</li>
                              ))}
                            </ul>
                          )
                        : (
                            ''
                          )}
                      <h4
                        className={classNames(
                          styles.experienceStep__title,
                          styles.durationCard__price,
                        )}
                      >
                        {price + ' ' + currency}
                      </h4>
                    </div>
                  </div>
                ),
              )}
            </div>
          ))}
        </div>
      </div>
      <div>
        <LinearCalendar
          selectedDate={date ?? undefined}
          startDate={closestAvailableDate}
          defaultGeneratedCalendarDayProps={defaultGeneratedCalendarDayProps}
          datesData={datesData}
          onDateSelect={handleSelectDate}
          onDatesRangeDataRequest={onDatesRangeDataRequest}
          isLoading={!isClosestAvailableDateReady}
          isDataRequestsAllowed={isClosestAvailableDateReady}
        />
      </div>
      <div className={styles.timeSlots}>
        {!isAvailableTimesReady ? <Loader fullSize width={80} height={80} /> : null}
        <h4 className={styles.experienceStep__title}>
          {date
            ? format(date, 'eeee, do MMMM yyyy:', {
              locale: dateLocales[i18n.language],
            })
            : ''}
        </h4>
        <div className={styles.timeSlots__list}>{timeList}</div>
      </div>
      <div className={styles.experienceStep__actions}>
        <Button
          appearance="outline"
          iconBefore
          label={t('booking.navigationButtons.back')}
          icon={<ArrowIcon style={{ transform: 'rotate(-180deg)' }} />}
          onClick={onBackward}
        />
        <Button
          disabled={isForwardButtonDisabled}
          label={t('booking.navigationButtons.summary')}
          icon={<ArrowIcon />}
          onClick={onForward}
        />
      </div>
      <BookingDetailsDrawer
        open={isDrawerOpen}
        onOpened={handleDrawerOpen}
        onClosed={handleDrawerClose}
        total={
          isPriceReady && priceSummary
            ? `${round(priceSummary.final_price_sum)} ${priceSummary.currency}`
            : undefined
        }
        priceDetails={isPriceReady ? priceDetails : []}
        summaryDetails={summaryDetails}
        currency={currency}
        vat={vat}
        loading={!isPriceReady}
      />
    </div>
  );
};

export default ExperienceStep;
