import React from 'react';
import cancelIcon from '../../images/cancel.svg';

interface CloseDialog {
  onClick: () => void;
}

const CloseDialog = ({ onClick }: CloseDialog) => (
  <img className="ru-dialog-close" src={cancelIcon} alt="" onClick={onClick} />
);

export default CloseDialog;
