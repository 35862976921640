import React, { MouseEvent } from 'react';
import { isBefore, startOfMonth, isAfter } from 'date-fns';

const Navbar = ({
  previousMonth,
  nextMonth,
  onPreviousClick,
  onNextClick,
  toMonth,
  fromMonth,
}: {
  previousMonth?: Date;
  nextMonth?: Date;
  onPreviousClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  onNextClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  toMonth: Date;
  fromMonth: Date;
}) => {
  const showNextMonth = (event: MouseEvent<HTMLSpanElement>) => onNextClick?.(event);

  const showPrevMonth = (event: MouseEvent<HTMLSpanElement>) => onPreviousClick?.(event);

  const disablePrev = previousMonth && isBefore(
    startOfMonth(previousMonth),
    startOfMonth(fromMonth ? fromMonth : new Date()),
  );

  const disableNext = nextMonth && toMonth
    ? isAfter(startOfMonth(nextMonth), startOfMonth(toMonth))
    : false;

  return (
    <div className="CalendarNavbar">
      <span
        className={disablePrev ? 'is-disabled' : ''}
        onClick={showPrevMonth}
      >
      </span>
      <span
        className={disableNext ? 'is-disabled' : ''}
        onClick={showNextMonth}
      >
      </span>
    </div>
  );
};

export default Navbar;
