import React, { useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import {
  getCustomer,
  getPaymentInfo,
  getPersonalQuotas,
} from '../../store/customer/customer.actions';
import { getMembershipPlans } from '../../store/membership/membership.actions';
import { getLounge } from '../../store/lounge/lounge.actions';
import { getGenderCodes } from '../../store/code/code.actions';
import { getCountries } from '../../store/country/country.actions';
import { getNotifications } from '../../store/notification/notification.actions';
import { getChampionshipsWithDispatch } from '../../store/championships/championships.actions';
import { getEvents } from '../../store/events/events.actions';
import { getTracks } from '../../store/track/track.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import BookingProvider from '../../providers/booking.provider';
import AuthLinks from '../../components/AuthLinks';
import Copy from '../../components/Copy';
import Profile from '../Profile';
import Billing from '../Billing';
import Booking from '../Booking';
import Notifications from '../Notifications';
import ChangePassword from '../ChangePassword';
import Ranking from '../Ranking';
import Dashboard from '../Dashboard';
import Events from '../Events';
import EventDetails from '../Events/EventDetails';
import Championships from '../Championships';
import Membership from '../Membership';
import Header from '../../components/Header/Header';

const Protected = () => {
  const customer = useWithSelection(customerSelector);
  const customerDispatch = useWithDispatch(getCustomer);
  const paymentInfoDispatch = useWithDispatch(getPaymentInfo);
  const personalQuotasWithDispatch = useWithDispatch(getPersonalQuotas);
  const membershipsDispatch = useWithDispatch(getMembershipPlans);
  const loungeDispatch = useWithDispatch(getLounge);
  const genderCodeDispatch = useWithDispatch(getGenderCodes);
  const countriesDispatch = useWithDispatch(getCountries);
  const membershipsWithDispatch = useWithDispatch(getMembershipPlans);
  const notificationsWithDispatch = useWithDispatch(getNotifications);
  const tracksDispatch = useWithDispatch(getTracks);
  const championshipsDispatch = useWithDispatch(getChampionshipsWithDispatch);
  const eventsDispatchs = useWithDispatch(getEvents);
  const [, i18n] = useTranslation();
  const location = useLocation();

  useEffect(() => {
    membershipsWithDispatch();
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    eventsDispatchs(i18n.language);
    championshipsDispatch(i18n.language);

    setTimeout(() => {
      personalQuotasWithDispatch();
    }, 300);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    customerDispatch();
    paymentInfoDispatch();
    membershipsDispatch();
    loungeDispatch();
    genderCodeDispatch();
    countriesDispatch();
    membershipsWithDispatch();
    notificationsWithDispatch();
    tracksDispatch();
    championshipsDispatch();
    personalQuotasWithDispatch();

    const notificationsInterval = setInterval(() => {
      notificationsWithDispatch();
    }, 30000);

    return () => {
      clearInterval(notificationsInterval);
    };
    // eslint-disable-next-line
  }, []);

  if (!customer) return <Navigate to={`/auth?redirectTo=${location.pathname}`} />;

  return (
    <BookingProvider>
      <Header />
      <div className="container">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetails />} />
          <Route path="/championships" element={<Championships />} />
          <Route path="/membership" element={<Membership />} />
        </Routes>
      </div>
      <footer>
        <Copy />
        <AuthLinks />
      </footer>
    </BookingProvider>
  );
};

export default Protected;
