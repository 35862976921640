import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isAfter } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import useWithSelection from '../../hooks/useWithSelection';
import { membershipsSelector } from '../../store/membership/membership.selectors';
import { customerQuotasSelector } from '../../store/customer/customer.selectors';
import { customerSelector } from '../../store/customer/customer.selectors';
import Button from '../../components/Button';
import { BookingContext } from '../../providers/booking.provider';
import icon from '../../images/ru-symbol-blue.svg';
import './Membership.scss';

const langs = {
  de,
  en,
  es,
};

const unlimitedArray = ['unlimited', 'unbegrenzt', 'ilimitado'];

const Membership = () => {
  const [t, i18n] = useTranslation();
  const memberships = useWithSelection(membershipsSelector);
  const customer = useWithSelection(customerSelector);
  const quotas = useWithSelection(customerQuotasSelector);
  const { openSubscriptions } = useContext(BookingContext);

  const clickHandler = () => {
    openSubscriptions(null);
  };

  const renewHanlder = () => openSubscriptions(null, true);

  let renewAvailable = false;
  let membershipExpired = false;

  if (customer?.membership_plan?.available && customer.membership_plan.expires_at) {
    const expiration = new Date(customer.membership_plan.expires_at);
    const today = new Date();

    membershipExpired = isAfter(today, expiration);
    renewAvailable = !!customer.membership_plan.renew_available;
  }

  return (
    <div className="Membership">
      <div className="row">
        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <h2>{t('membership.title')}</h2>
          <h4>{t('membership.subtitle')}</h4>

          <div className="Membership-details">
            <div>
              {t('membership.current.label')}
              :
              <span>
                <img src={icon} alt="" />
                {' '}
                {customer?.membership_plan?.name}
              </span>
            </div>
            <div>
              {t('membership.details')}
              :
              <span>
                {customer?.membership_plan?.description
                  ? customer.membership_plan.description
                  : 'N/A'}
                {customer?.membership_plan?.base_price_sum && `, ${customer.membership_plan.base_price_sum} ${customer.membership_plan.currency}`}
              </span>
            </div>
            <div>
              {t('membership.expiry.label')}
              :
              {!customer?.membership_plan?.expires_at && <span>N/A</span>}
              {customer?.membership_plan?.expires_at && !membershipExpired && (
                <span>
                  {t('membership.membershipExpiresAt')}
                  {format(
                    new Date(customer.membership_plan.expires_at),
                    'do MMMM yyyy',
                    { locale: langs[i18n.language as keyof typeof langs] },
                  )}
                  {' '}
                  @24h
                </span>
              )}
              {customer?.membership_plan?.expires_at && membershipExpired && (
                <>
                  <span className="Membership-expired">
                    {t('membership.membershipExpiredAt')}
                    {' '}
                    {`${format(
                      new Date(customer.membership_plan.expires_at),
                      'do MMMM yyyy',
                      { locale: langs[i18n.language as keyof typeof langs] },
                    )} @24h`}
                  </span>
                </>
              )}
            </div>
            <div>
              {t('membership.status')}
              <div className="Membership-quota-wrapper">
                {quotas
                && quotas.map((q, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-sm-6">
                        <div className="Membership-quota">
                          <span>{q.pricing.label}</span>
                          {q.pricing.description}
                        </div>
                      </div>
                      {q.limit.customer_limit.show && (
                        <div className="col-sm-6">
                          <div
                            className={`Membership-quota ${
                              !unlimitedArray.includes(
                                q.limit.customer_limit.total,
                              )
                              && q.limit.customer_limit.used
                              === q.limit.customer_limit.total
                                ? 'disabled'
                                : ''
                            }`}
                          >
                            <span>
                              {!unlimitedArray.includes(
                                q.limit.customer_limit.total,
                              )
                                ? `${q.limit.customer_limit.used}
                              /${q.limit.customer_limit.total}`
                                : q.limit.customer_limit.total}
                            </span>
                            {q.limit.customer_limit.description}
                          </div>
                        </div>
                      )}
                      {/* {q.limit.quantity_limit.show &&  <div className="col-sm-6">
                          <div className="Membership-quota">
                            <span>
                              {q.limit.quantity_limit.total !== 'unlimited'
                                ? `${q.limit.quantity_limit.remaining}/${q.limit.quantity_limit.total}`
                                : 'unlimited'}
                            </span>
                            {q.limit.quantity_limit.description}
                          </div>
                        </div>} */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="Membership-actions">
              {memberships && memberships.length > 0 && (
                <Button
                  label={t('membership.upgrade')}
                  onClick={clickHandler}
                  disabled={!memberships.length}
                />
              )}

              {renewAvailable && (
                <Button
                  onClick={renewHanlder}
                  label={t('membership.formTitle')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
