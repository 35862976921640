import { mergeRefs } from '@utils/merge-refs.utils';
import isNumber from 'lodash/isNumber';
import React, { useMemo, useCallback, forwardRef, useRef, useEffect } from 'react';

export enum ENumberInputLimitType {
  Min = 'min',
  Max = 'max',
}

export interface INumberInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'value' | 'onChange'> {
  integer?: boolean;
  min?: number;
  max?: number;
  value?: number | null;
  onChange?: (value: number | null) => void;
  onLimitExceed?: (type: ENumberInputLimitType) => void;
}

export const NumberInput = forwardRef<HTMLInputElement, INumberInputProps>(
  function NumberInput({ integer, min, max, value, onChange, onLimitExceed, ...restProps }, ref) {
    const isContainsDangleDotRef = useRef(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const mergeInputRef = useMemo(() => mergeRefs([inputRef, ref]), [inputRef, ref]);

    const minAllowedValue = useMemo(() => min ?? Number.MIN_VALUE, [min]);
    const maxAllowedValue = useMemo(() => max ?? Number.MAX_VALUE, [max]);

    const transformValueToAllowed = useCallback((value: number | null) => {
      if (value === null) return null;
      return value
        ? Math.min(Math.max(value, minAllowedValue), maxAllowedValue)
        : minAllowedValue;
    }, [minAllowedValue, maxAllowedValue]);

    const allowedValue = useMemo(
      () => value ? transformValueToAllowed(Number(value)) : null,
      [value, transformValueToAllowed],
    );

    const valueAsString = useMemo(() => {
      if (allowedValue === null || Number.isNaN(Number(allowedValue)) || !allowedValue) return '';
      const numberSuffix = isContainsDangleDotRef.current ? '.' : '';
      return integer ? allowedValue.toFixed(0) : `${allowedValue}${numberSuffix}`;
    }, [integer, allowedValue]);

    const handleValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const preprocessedValue = event.target.value
        .replace(/[^0-9.]/g, '')
        .replace(/\.+/g, '.')
        .split('.')
        .slice(0, integer ? 1 : 2)
        .join('.');
      isContainsDangleDotRef.current = preprocessedValue.endsWith('.');
      const valueAsNumberOrNull = preprocessedValue === '' || Number.isNaN(Number(preprocessedValue))
        ? null
        : Number(preprocessedValue);
      const allowedNewValue = transformValueToAllowed(valueAsNumberOrNull);
      if (isNumber(valueAsNumberOrNull) && valueAsNumberOrNull > maxAllowedValue) {
        onLimitExceed?.(ENumberInputLimitType.Max);
      }
      if (isNumber(valueAsNumberOrNull) && valueAsNumberOrNull < minAllowedValue) {
        onLimitExceed?.(ENumberInputLimitType.Min);
      }
      event.target.value = allowedNewValue === null ? '' : allowedNewValue.toString();
      onChange?.(allowedNewValue);
    }, [integer, maxAllowedValue, minAllowedValue, onChange, onLimitExceed, transformValueToAllowed]);

    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current.value = valueAsString;
    }, [valueAsString]);

    return (
      <input
        {...restProps}
        ref={mergeInputRef}
        type="text"
        onChange={handleValueChange}
      />
    );
  },
);
