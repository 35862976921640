import {
  CodeState,
  CodeActionTypes,
  GET_GENDERS,
  GET_EVENT_TYPES,
} from './code.types';

const initialState: CodeState = {
  genders: [],
  eventTypes: [],
};

export default (state = initialState, action: CodeActionTypes) => {
  switch (action.type) {
    case GET_GENDERS:
      return {
        ...state,
        genders: [...action.payload],
      };
    case GET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: [...action.payload],
      };

    default:
      return state;
  }
};
