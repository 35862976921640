import React, { useMemo } from 'react';
import ProfileForm from './ProfileForm';
import { Location, useLocation } from 'react-router-dom';
import styles from './Profile.module.scss';

const Profile = () => {
  const location = useLocation() as Location<{ data_incomplete?: boolean }>;

  const data_incomplete = useMemo(
    () =>
      location.state && location.state.data_incomplete ? !!location.state.data_incomplete : false,
    [location],
  );

  return (
    <div className={styles.profile}>
      <ProfileForm data_incomplete={data_incomplete} />
    </div>
  );
};

export default Profile;
