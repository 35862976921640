import React, { useContext } from 'react';
import { BookingContext } from '../../providers/booking.provider';
import Select from '../FormField/Select';
import useWithSelection from '../../hooks/useWithSelection';
import { membershipsSelector } from '../../store/membership/membership.selectors';
import { customerSelector } from '../../store/customer/customer.selectors';
import { MembershipPlan } from '../../store/membership/membership.types';
import { SetStateType } from '../../types/utils.types';

interface PlansDropdown {
  selected: MembershipPlan | null;
  setPlan: SetStateType<MembershipPlan | null>;
}

const PlansDropdown = React.memo(function PlansDropdown({ selected, setPlan }: PlansDropdown) {
  const { renewMembership } = useContext(BookingContext);
  const { membership_plan } = useWithSelection(customerSelector);
  let plans = useWithSelection(membershipsSelector);
  const setFieldValue = (name: string, value: string) => {
    const plan = plans.find((plan: MembershipPlan) => plan.id === value);
    setPlan(plan ?? null);
  };

  if (renewMembership) {
    plans = membership_plan ? [membership_plan] : [];
  }

  return (
    <div className="PlansDropdown">
      <Select
        i18nKey="membership"
        name="base-center"
        value={selected ? selected.id + '' : ''}
        list={plans}
        setFieldValue={setFieldValue}
      />
    </div>
  );
});

export default PlansDropdown;
