import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import de from './de';
import es from './sp';

let lng: string = 'en';

if (navigator.language.toLowerCase().match('de')) lng = 'de';
if (navigator.language.toLowerCase().match('es')) lng = 'es';

if (localStorage) {
  const ruState = JSON.parse(localStorage.getItem('ruState')!) as { customer: { preferred_language: string } };
  if (ruState && ruState.customer) lng = ruState.customer.preferred_language;
}

const resources: Resource = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: ['en', 'de', 'es'],
  interpolation: { escapeValue: false },
});

export default i18n;
