import { Dispatch } from 'react';
import api from '../../config/api.config';

import {
  GET_MEMBERSHIPS_PLANS,
  MembershipActionTypes,
  MembershipPlan,
} from './membership.types';
import { Customer, SET_MEMBER } from '../customer/customer.types';
import { AxiosError } from 'axios';

export const getMembershipPlans = async (
  dispatch: Dispatch<MembershipActionTypes>,
) => {
  try {
    const response = await api.get<{ data?: MembershipPlan[] }>('/memberships');
    if (typeof response === 'undefined' || !response) return;

    dispatch({
      type: GET_MEMBERSHIPS_PLANS,
      payload: response?.data?.data ?? [],
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const postMembershipConvert = async (data: object) => {
  try {
    const response = await api.post('/memberships/convert', { cookies: document.cookie, data: data });
    if (typeof response === 'undefined' || !response) return;
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const updateMembership = async (
  dispatch: Dispatch<MembershipActionTypes>,
  plan: MembershipPlan,
  card_token?: string,
  save_card?: boolean,
) => {
  try {
    const customer = await api.patch<TRumpApiResponseData<Customer>>('/membership', {
      plan: plan.id,
      card_token,
      save_card,
    });
    dispatch({
      type: SET_MEMBER,
      payload: customer.data.data,
    });

    await getMembershipPlans(dispatch);

    return customer.data;
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const renewMembershipAction = async (
  dispatch: Dispatch<MembershipActionTypes>,
  card_token?: string,
  save_card?: boolean,
) => {
  try {
    const customer = await api.patch<TRumpApiResponseData<Customer>>('/membership/renew', {
      card_token,
      save_card,
    });
    dispatch({
      type: SET_MEMBER,
      payload: customer.data.data,
    });

    await getMembershipPlans(dispatch);

    return customer.data;
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};
