import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipPlan } from '../../../store/membership/membership.types';
import { BookingContext } from '../../../providers/booking.provider';
import Button from '../../../components/Button';

const Subscription = ({ plan }: { plan: MembershipPlan }) => {
  const [t] = useTranslation();
  const { openSubscriptions } = useContext(BookingContext);

  const clickHandler = () => {
    openSubscriptions(plan);
  };

  return (
    <div className="dashboard-widget">
      <h3>{plan.name}</h3>
      <p>{plan.description}</p>
      <p className="dashboard-widget-vat">
        {t('booking.vatInfo', { vat: plan.vat, currency: plan.currency })}
      </p>
      <Button label={t('membership.selectPlan')} onClick={clickHandler} />
    </div>
  );
};

export default Subscription;
