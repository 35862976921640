import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useWithSelection from '../../hooks/useWithSelection';
import { paymentInfoSelector } from '../../store/customer/customer.selectors';
import Button from '../Button';
import amex from '../../images/amex.svg';
import visa from '../../images/visa-card.svg';
import mastercard from '../../images/mastercard.svg';

import './PaymentDetails.scss';

interface PaymentDetails {
  onPay: (event: MouseEvent<HTMLButtonElement>) => void;
  showCardForm: () => void;
  isSubmitting: boolean;
}

const fetchCardImage = (brand: string): string | undefined => {
  switch (brand.toLowerCase()) {
    case 'visa':
      return visa;
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    default:
      return undefined;
  }
};

const PaymentDetails = ({
  showCardForm,
  onPay,
  isSubmitting,
}: PaymentDetails) => {
  const [t] = useTranslation();
  const paymentInfo = useWithSelection(paymentInfoSelector);
  const cardImage = fetchCardImage(paymentInfo.card_brand);

  return (
    <div className="PaymentDetails">
      <h2>{t('billing.detailsTitle')}</h2>
      {paymentInfo && (
        <>
          <div className="PaymentDetails-data">
            {cardImage && <img src={cardImage} alt={paymentInfo.card_brand} />}
            <div>
              <span>
                •••• •••• ••••
                {paymentInfo.card_number_fragment}
              </span>
            </div>
          </div>
          <div className="PaymentDetails-footer">
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              label={t('booking.payNow')}
              onClick={onPay}
            />

            <span onClick={showCardForm}>
              {t('billing.updateDetailsTitle')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentDetails;
