import { FormModelField } from '../../types/form.types';

interface Values {
  password: string;
  password_confirmation: string;
}

export const initialValues: Values = {
  password: '',
  password_confirmation: '',
};

export const formModel: FormModelField[] = [
  {
    name: 'password',
    i18nKey: 'field.password',
    type: 'password',
  },
  {
    name: 'password_confirmation',
    i18nKey: 'field.password_confirmation',
    type: 'password',
  },
];
