import React, { forwardRef, useImperativeHandle } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import styles from './ConfirmationDialog.module.scss';
import MobileSlideUpDialog from '../MobileSlideUpDialog/MobileSlideUpDialog';

export interface IConfirmationDialogRef {
  open: () => void;
  close: () => void;
}

export interface IConfirmationDialogProps {
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  okText?: string;
  extraActionText?: string;
  cancelText?: string;
  okIcon?: React.ReactNode;
  extraActionIcon?: React.ReactNode;
  type?: 'default' | 'delete';
  onConfirm: () => void;
  onCancel: () => void;
  onExtraAction: () => void;
}

const ConfirmationDialog = forwardRef<
  IConfirmationDialogRef,
  IConfirmationDialogProps
>(function ConfirmationDialog(
  {
    title,
    text,
    okText,
    cancelText,
    extraActionText,
    extraActionIcon,
    okIcon,
    type,
    onCancel,
    onConfirm,
    onExtraAction,
  },
  ref,
) {
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    [],
  );

  return (
    <MobileSlideUpDialog className={styles.dialog} open={open}>
      <div className={styles.content}>
        {title
          ? (
              <h1 className={styles.content__title}>
                {typeof title === 'string' ? t(title) : title}
              </h1>
            )
          : (
              ''
            )}
        {text
          ? (
              <p className={styles.content__text}>
                {typeof text === 'string' ? t(text) : text}
              </p>
            )
          : (
              ''
            )}

        <div className={styles.content__buttons}>
          <Button
            appearance="outline"
            label={cancelText ? t(cancelText) : ''}
            onClick={onCancel}
          />
          {extraActionText || extraActionIcon
            ? (
                <Button
                  appearance="outline"
                  label={extraActionText ? t(extraActionText) : ''}
                  icon={extraActionIcon}
                  onClick={onExtraAction}
                />
              )
            : (
                ''
              )}
          <Button
            color={type === 'delete' ? 'error' : 'primary'}
            label={okText ? t(okText) : ''}
            icon={okIcon}
            onClick={onConfirm}
          />
        </div>
      </div>
    </MobileSlideUpDialog>
  );
});

export default ConfirmationDialog;
