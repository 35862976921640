import React from 'react';
import MembershipWidget from '../../components/MembershipWidget';
import BookingWidget from '../../components/BookingWidget';
import RankingWidget from './RankingWidget';
import CustomerWidget from './CustomerWidget';
import EventsWidget from './EventsWidget';
import DraftEventWidget from './DraftEventWidget';

import styles from './Dashboard.module.scss';
import classNames from 'classnames';

const Dashboard = () => (
  <div className={styles.root}>
    <div className={styles.root__row}>
      <div className={classNames(styles.root__block, styles.root__customer)}>
        <CustomerWidget />
      </div>
      <div className={classNames(styles.root__row, styles.root__bookingMembership)}>
        <div className={styles.root__block}>
          <BookingWidget />
        </div>
        <div className={styles.root__block}>
          <MembershipWidget />
        </div>
      </div>
    </div>
    <DraftEventWidget />
    <div className={styles.root__row}>
      <RankingWidget className={styles.root__block} />
      <EventsWidget className={styles.root__block} />
    </div>

  </div>
);

export default Dashboard;
