import React from 'react';
import { useTranslation } from 'react-i18next';
import BookingWidget from '../../components/BookingWidget';
import { membershipsSelector } from '../../store/membership/membership.selectors';
import { MembershipPlan } from '../../store/membership/membership.types';
import useWithSelection from '../../hooks/useWithSelection';
import Subscription from './Subscription';

import './Booking.scss';

const Booking = () => {
  const [t] = useTranslation();
  const plans = useWithSelection(membershipsSelector);

  return (
    <div className="Booking">
      <div className="row widget-wrapper">
        <div className="col-12">
          <h2>{t('booking.pageTitle')}</h2>
        </div>
        <div className="widgets">
          <BookingWidget />
        </div>
      </div>
      {plans && plans.length > 0 && (
        <div className="widget-wrapper slider">
          <div>
            <h2>{t('booking.memberships')}</h2>
          </div>
          <div className="booking-subscriptions">
            {plans.map((sub: MembershipPlan) => (
              <Subscription key={sub.id} plan={sub} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Booking;
