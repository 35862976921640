export const GET_GENDERS = 'GET_GENDERS';
export const GET_EVENT_TYPES = 'GET_EVENT_TYPES';

export interface Code {
  id: string;
  name: string;
  slug: string;
}

export interface CodeState {
  genders: Code[];
  eventTypes: Code[];
}

interface GetGendersAction {
  type: typeof GET_GENDERS;
  payload: Code[];
}

interface GetEventTypesAction {
  type: typeof GET_EVENT_TYPES;
  payload: Code[];
}

export type CodeActionTypes = GetGendersAction | GetEventTypesAction;
