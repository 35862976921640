import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  TOGLE_NOTIFICATIONS,
  NotificationActionTypes,
  NotificationsState,
  Notification,
} from './notification.types';

const initialState: NotificationsState = {
  notifications: [],
  unseen_count: 0,
};

export default (state = initialState, action: NotificationActionTypes) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        unseen_count: action.payload.unseen_count,
      };
    case DELETE_NOTIFICATIONS:
      return {
        ...state,
        notifications:
          state.notifications
          && state.notifications.filter(
            (e: Notification) =>
              action.payload.notification
              && action.payload.notification.id !== e.id,
          ),
        unseen_count: action.payload.unseen_count,
      };
    case TOGLE_NOTIFICATIONS:
      return {
        ...state,
        unseen_count: state.unseen_count - 1,
        notifications: state.notifications.map((n: Notification) => {
          if (n.id === action.payload) n.seen = true;
          return n;
        }),
      };
    default:
      return state;
  }
};
