import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notificationSelector } from '../../../store/notification/notification.selectors';
import { Notification as INotification } from '../../../store/notification/notification.types';

import Notification from './Notification';

import './NotificationsTable.scss';

const NotificationsTable = () => {
  const [t] = useTranslation();
  const notifications = useSelector(notificationSelector);

  return (
    <div className="Notifications-table">
      <div className="Notifications-cells Notifications-title">
        <span>{t('notifications.date')}</span>
        <span>{t('notifications.message')}</span>
      </div>
      {notifications && notifications.length !== 0
        ? (
            notifications.map((notification: INotification) => (
              <Notification {...notification} key={notification.id} />
            ))
          )
        : (
            <div className="Notifications-cells-empty">
              {t('notifications.noItems')}
            </div>
          )}
    </div>
  );
};

export default NotificationsTable;
