import i18n from '../../i18n/index';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { Simulator } from './simulator.types';

const options = {
  headers: {
    'x-locale': i18n.language,
  },
};

export const getLoungeSimulators = async (
  id?: number | string,
) => {
  try {
    const event = await api.get<{ data?: Simulator[] }>(`/simulators/by_lounge/${id}`, options);
    return event.data.data;
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string') toastUtil('error', error.data.meta.message);
  }
};
