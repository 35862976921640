import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './RideSummary.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button';
import { ReactComponent as EditIcon } from '../../../../../images/edit.svg?tsx';
import { ReactComponent as CheckIcon } from '../../../../../images/check-white.svg?tsx';
import { ReactComponent as LogOutIcon } from '../../../../../images/log-out.svg?tsx';
import AmexIcon from '../../../../../images/amex.svg';
import VisaIcon from '../../../../../images/visa-card.svg';
import MastercardIcon from '../../../../../images/mastercard.svg';
import AppFormField from '../../../../../components/AppFormField/AppFormField';
import AppInput from '../../../../../components/AppInput';
import CheckboxRadio from '../../../../../components/FormField/CheckboxRadio';
import { EBookingPayment, IRideBookingForm } from '../../../RideBooking';
import { getFormError } from '../../../../../utils/get-form-error.utils';
import { requiredTextValidator } from '../../../../../validators/requiredText.validator';
import { emailValidator } from '../../../../../validators/email.validator';
import Loader from '../../../../../components/Loader';
import CardForm from '../../CardForm';
import type { Stripe, StripeElements } from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { toastUtil } from '../../../../../utils/toast.utils';
import { Lounge } from '../../../../../store/lounge/lounge.types';
import useWithSelection from '../../../../../hooks/useWithSelection';
import { customerSelector } from '../../../../../store/customer/customer.selectors';
import { Customer } from '../../../../../store/customer/customer.types';
import BookingDetailsList, {
  BookingDetail,
} from '../../../../../components/BookingDetailsList/BookingDetailsList';
import { SummaryType } from '../../../../../store/booking/booking.types';
import BookingPriceDetails, {
  BookingPriceDetail,
} from '../../../../../components/BookingPriceDetails/BookingPriceDetails';
import BookingCouponField from '../../../../../components/BookingCouponField/BookingCouponField';
import AppAlert from '../../../../../components/AppAlert';
import { useConfirmationDialog } from '../../../../../components/ConfirmationDialog/useConfirmationDialog';
import useWithDispatch from '../../../../../hooks/useWithDispatch';
import { signOut } from '../../../../../store/customer/customer.actions';
import StyledTooltip from '../../../../../components/StyledTooltip/StyledTooltip';
import { Controller } from '../../../../../components/FormField/Controller';

interface IRideSummaryProps {
  selectedLounge?: Lounge | null;
  summaryDetails: BookingDetail[];
  priceSummary: SummaryType | null;
  priceDetails: BookingPriceDetail[];
  isPriceLoading: boolean;
  isSending: boolean;
  toggleSummary: () => void;
  openLogin: () => void;
  onSubmit: (cardToken: string) => void;
}

const LOG_OUT_DIALOG_CONFIG = {
  title: 'auth.logOut.confirmationTitle',
  text: 'auth.logOut.confirmationSubtitle',
  okText: 'auth.logOut.btnText',
  okIcon: <LogOutIcon />,
};

const RideSummary: React.FC<IRideSummaryProps> = ({
  selectedLounge,
  summaryDetails,
  priceSummary,
  priceDetails,
  isPriceLoading,
  isSending,
  toggleSummary,
  openLogin,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const {
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    reset,
    getValues,
  } = useFormContext<IRideBookingForm>();
  const stripeRef = useRef<Stripe | null>(null);
  const stripeElementsRef = useRef<StripeElements | null>(null);
  const customer: Customer = useWithSelection(customerSelector);
  const signOutAction = useWithDispatch(signOut);
  const { openDialog, dialog } = useConfirmationDialog();
  const [privacyAndTos, setPrivacyAndTos] = useState<boolean>(false);
  const [isEmailDirty, setIsEmailDirty] = useState<boolean>(false);

  const { payment, createUser } = watch(['payment', 'createUser']);

  useEffect(() => {
    if (customer) {
      reset({
        ...getValues(),
        firstName: customer.first_name,
        lastName: customer.last_name,
        email: customer.email,
        createUser: false,
      });
      setPrivacyAndTos(true);
    }
    else {
      setPrivacyAndTos(false);
    }

    // eslint-disable-next-line
  }, [customer]);

  const onLogOut = async () => {
    const result = await openDialog(LOG_OUT_DIALOG_CONFIG);

    if (!result) return;

    signOutAction();

    toastUtil('success', t('auth.logOut.successMessage'));
  };

  const createToken = async () => {
    try {
      if (!stripeRef.current || !stripeElementsRef.current) {
        throw new Error('The stripe refs are not found.');
      }

      const cardNumberElement
        = stripeElementsRef.current.getElement(CardNumberElement);

      if (!cardNumberElement) {
        throw new Error('The CardNumberElement is not found.');
      }

      const stripeResponse = await stripeRef.current.createToken(
        cardNumberElement,
      );

      if (stripeResponse.error) {
        const error = { meta: { message: stripeResponse.error.message } };
        throw error;
      }

      return stripeResponse.token.id;
    }
    catch (err) {
      return Promise.reject(err as Error);
    }
  };

  const onConfirm = async () => {
    try {
      let token: string = '';
      if (payment === EBookingPayment.card) token = await createToken();
      onSubmit(token);
    }
    catch (err) {
      const error = err as TRumpApiErrorResponseData;
      if (typeof error?.meta?.message === 'string')
        toastUtil('error', error.meta.message);
    }
  };

  const handleEditBookingClick = useCallback(() => {
    toggleSummary();
    setValue('coupon', '');
  }, [setValue, toggleSummary]);

  return (
    <div className={styles.rideSummary}>
      <div className={styles.rideSummary__column}>
        <h3 className={styles.rideSummary__title}>
          {t('booking.steps.summary.bookingSummary')}
        </h3>
        <BookingDetailsList details={summaryDetails} />
        <Button
          appearance="outline"
          disabled={isSending}
          className={styles.rideSummary__button}
          icon={<EditIcon />}
          label={t('booking.steps.summary.editBookingDetails')}
          onClick={handleEditBookingClick}
        />
      </div>
      <div className={styles.rideSummary__column}>
        <div className={styles.rideSummary__column}>
          <h3 className={styles.rideSummary__title}>
            {t('booking.priceSummary')}
          </h3>
          {isPriceLoading
            ? (
                <Loader color="dark-blue" height={80} width="100%" />
              )
            : (
                <BookingPriceDetails
                  details={priceDetails}
                  priceSummary={priceSummary}
                  emptyStateKey="booking.rideSummaryEmptyState"
                />
              )}
          <BookingCouponField disabled={isSending} onSend={(coupon) => setValue('coupon', coupon)} />
        </div>
      </div>
      <div className={styles.rideSummary__column}>
        <div className={styles.rideSummary__column}>
          <div className={styles.billingHeader}>
            <h3 className={styles.rideSummary__title}>
              {t('booking.steps.summary.billingDetails')}
            </h3>

            <Button
              appearance="outline"
              disabled={isSending}
              label={
                customer ? t('auth.logOut.btnText') : t('auth.logIn.btnText')
              }
              onClick={() => (customer ? onLogOut() : openLogin())}
            />
          </div>
          <Controller
            control={control}
            name="firstName"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 }),
              },
              ...requiredTextValidator({
                required: t('field.firstName.required'),
              }),
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label={t('field.firstName.label')}
                error={getFormError('firstName', errors)}
              >
                <StyledTooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top-start"
                  title={
                    customer ? t('booking.steps.summary.billingTooltip') : ''
                  }
                >
                  <div>
                    <AppInput
                      disabled={!!customer || isSending}
                      placeholder="field.firstName.placeholder"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                </StyledTooltip>
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 }),
              },
              ...requiredTextValidator({
                required: t('field.lastName.required'),
              }),
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.lastName.label"
                error={getFormError('lastName', errors)}
              >
                <StyledTooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top-start"
                  title={
                    customer ? t('booking.steps.summary.billingTooltip') : ''
                  }
                >
                  <div>
                    <AppInput
                      disabled={!!customer || isSending}
                      placeholder="field.lastName.placeholder"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                </StyledTooltip>
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              ...emailValidator({
                required: t('field.email.required'),
                minLength: t('field.validations.minLength', { length: 6 }),
                maxLength: t('field.validations.maxLength', { length: 64 }),
                invalid: t('field.email.invalidEmail'),
              }),
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.identifier.label"
                error={isEmailDirty || errors.email?.type === 'manual' ? getFormError('email', errors) : undefined}
              >
                <StyledTooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top-start"
                  title={
                    customer ? t('booking.steps.summary.billingTooltip') : ''
                  }
                >
                  <div>
                    <AppInput
                      disabled={!!customer || isSending}
                      placeholder="field.email.placeholder"
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();

                        if (!isEmailDirty) setIsEmailDirty(!!value);
                      }}
                    />
                  </div>
                </StyledTooltip>
              </AppFormField>
            )}
          />
          {customer
            ? (
                ''
              )
            : (
                <Controller
                  control={control}
                  name="createUser"
                  render={({ value, onChange }) => (
                    <CheckboxRadio
                      primary
                      disabled={isSending}
                      checked={value}
                      name="createUser"
                      value="createUser"
                      i18nKey="field.createUser.label"
                      onChange={({ target: { checked } }) => onChange(checked)}
                    />
                  )}
                />
              )}
          {createUser
            ? (
                <AppAlert
                  bordered
                  text="booking.steps.summary.newUserInfo"
                  type="info"
                />
              )
            : (
                ''
              )}
        </div>
        <div className={styles.rideSummary__column}>
          <h3 className={styles.rideSummary__title}>
            {t('booking.steps.summary.payment')}
          </h3>
          <div className={styles.payment}>
            <div className={styles.payment__item}>
              <div
                className={styles.payment__itemLabel}
                onClick={() => !isSending && setValue('payment', EBookingPayment.lounge)}
              >
                <div className={styles.payment__block}>
                  <input
                    disabled={isSending}
                    type="radio"
                    name="payment"
                    value={EBookingPayment.lounge}
                    checked={payment === EBookingPayment.lounge}
                    onChange={() => setValue('payment', EBookingPayment.lounge)}
                  />
                  {t('booking.steps.summary.payInLounge')}
                </div>
              </div>
            </div>
            {selectedLounge && !selectedLounge.accepts_cash_only
              ? (
                  <div className={styles.payment__item}>
                    <div className={styles.payment__itemLabel}>
                      <div
                        className={styles.payment__itemLabel}
                        onClick={() => !isSending && setValue('payment', EBookingPayment.card)}
                      >
                        <div className={styles.payment__block}>
                          <input
                            disabled={isSending}
                            type="radio"
                            name="payment"
                            value={EBookingPayment.card}
                            checked={payment === EBookingPayment.card}
                            onChange={() =>
                              setValue('payment', EBookingPayment.card)}
                          />
                          {t('booking.steps.summary.payByCard')}
                        </div>
                        <div className={styles.payment__block}>
                          <img src={VisaIcon} alt="visa" />
                          <img src={AmexIcon} alt="amex" />
                          <img src={MastercardIcon} alt="mastercard" />
                        </div>
                      </div>
                    </div>
                    {payment === EBookingPayment.card
                      ? (
                          <div className={styles.card}>
                            <CardForm
                              onStripeChange={(stripe) => (stripeRef.current = stripe)}
                              onStripeElementsChange={(stripeElements) =>
                                (stripeElementsRef.current = stripeElements)}
                            />
                          </div>
                        )
                      : (
                          ''
                        )}
                  </div>
                )
              : (
                  ''
                )}
          </div>
        </div>
        {customer
          ? (
              ''
            )
          : (
              <div className={styles.rideSummary__checkboxes}>
                <CheckboxRadio
                  primary
                  required
                  disabled={isSending}
                  checked={privacyAndTos}
                  name="privacyPolicy"
                  value="privacyPolicy"
                  i18nTrans={(
                    <Trans i18nKey="field.privacyAndTos.label">
                      <a
                        className={styles.rideSummary__link}
                        href={t('field.privacyPolicy.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      <a
                        className={styles.rideSummary__link}
                        href={t('field.termsOfUse.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                    </Trans>
                  )}
                  onChange={({ target: { checked } }) => setPrivacyAndTos(checked)}
                />
              </div>
            )}
        <div className={styles.rideSummary__action}>
          <Button
            isLoading={isSending}
            disabled={!privacyAndTos || !isValid || isPriceLoading || isSending}
            label={t('booking.steps.summary.confirmAndProceedButton')}
            icon={<CheckIcon />}
            onClick={onConfirm}
          />
        </div>
      </div>
      {dialog}
    </div>
  );
};

export default RideSummary;
